<template>
	<div class="performance performance--overview">
		<div class="performance__col">
			<number-bars class="number-bars--orange number-bars--full-width" :title="`Defined Contribution \nClearance and Settlement`" unit="( Yearly Volume in millions )" :numbers="definedContribution" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'AlternativeInvestment2',
	components: {
		NumberBars,
	},
	data: () => ({
		definedContribution: [
			{
				value: 151.2,
				label: '2020',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 144.4,
				label: '2019',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 137.2,
				label: '2018',
				options: {
					decimalPlaces: 1,
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
